import React from "react"

/* Import Local Styles */
import "../icon.css"

const CircleSvg = ({ color = "#FFFFFF" }) => (
  <svg
    className="icon no-fill circle"
    viewBox="0 0 108 108"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="54" cy="54" r="54" fill={color} />
  </svg>
)

export default CircleSvg
