import React from "react"

/* Import Global Components */
import ColorPicker from "~components/colorPicker"

/* Import Local Components */
import Slide from "../../components/slide"

/* Import Local Styles */
import "./colors.css"

const ColorsSlide = () => {
  return (
    <Slide title="colors">
      <div className="colors-container">
        <div>
          <div>
            <p>Pick a Background</p>
          </div>
          <div>
            <ColorPicker newColor={"white"} newBackgroundColor={"black"} />
            <ColorPicker newColor={"black"} newBackgroundColor={"yellow"} />
            <ColorPicker newColor={"black"} newBackgroundColor={"purple"} />
            <ColorPicker newColor={"black"} newBackgroundColor={"white"} />
          </div>
        </div>
      </div>
    </Slide>
  )
}

export default ColorsSlide
