import React, { useState, useContext } from "react"
import classnames from "classnames"

/* Import Global Contexts */
import ColorContext from "~context/color"

/* Import Global Components */
import Circle from "~components/icon/normal/circle"
import PlusInCircle from "~components/icon/normal/plusInCircle"

/* Import Local Styles */
import "./color-picker.css"

const ColorPicker = ({
  newColor = "white",
  newBackgroundColor = "black",
  newMenuColor = "purple",
  newMenuBackgroundColor = "black",
}) => {
  const [color, setColor] = useContext(ColorContext)
  const [hover, setHover] = useState(false)

  const handleClick = (text, background) => {
    setColor({ text, background, newMenuColor, newMenuBackgroundColor })
  }

  const active = color.background === newBackgroundColor

  return (
    <button
      className={classnames("color-picker", { active: active, hover: hover })}
      onClick={() => {
        handleClick(
          newColor,
          newBackgroundColor,
          newMenuColor,
          newMenuBackgroundColor
        )
      }}
      onMouseEnter={() => {
        setHover(true)
      }}
      onMouseLeave={() => {
        setHover(false)
      }}
    >
      <PlusInCircle />
      <Circle color={`var(--color-${newBackgroundColor})`} />
    </button>
  )
}

export default ColorPicker
